/* html과 body 요소를 중앙에 배치 */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* 배경색 설정 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: white; /* body 배경색 설정 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: block;
  overflow: hidden;
}

/* 더 큰 해상도에 대한 설정 */
/* 1920픽셀이라면 100vw / 1280는 1920 / 1280 = 1.5 -> 1.5배 확대*/
@media (min-width: 1281px) and (min-height: 721px) {
  html,
  body {
    transform: scale(calc(min(100vw / 1280, 100vh / 720)));
    transform-origin: top left;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}
